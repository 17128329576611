import React, { useMemo } from 'react';
// import { GlobalHeader, GlobalFooter } from '@/components/Layouts';
import classNames from 'classnames';
import './index.less';
import { AppSettings, PageType } from '@/shared/app-common';
import { useTranslation } from 'next-i18next';
import { EventName } from '@/shared/event-types';
import { useRouter } from 'next/router';
import { useUserProvider } from '@/context/UserProvider';
import { useWindowSize } from 'react-use';

import dynamic from 'next/dynamic';

const GlobalHeader = dynamic(() => import('@/components/Layouts/GlobalHeader'), { ssr: true });
const GlobalFooter = dynamic(() => import('@/components/Layouts/GlobalFooter'), { ssr: true });

interface LayoutProps {
  head?: React.ReactNode;
  children: React.ReactNode;
  showHeader?: boolean;
  showFooter?: boolean;
  pageType?: PageType;
}

const Layout = ({
  children,
  head,
  showHeader = true,
  showFooter = true,
  pageType = PageType.Normal,
}: LayoutProps) => {
  // const { isMobile } = useConfigProvider();
  const { isLogin } = useUserProvider();
  const { t } = useTranslation();
  // startData和其他样式区分
  const router = useRouter();
  // const ref = useRef(null);
  const { width } = useWindowSize()

  const getMarginTop = useMemo(() => {

    let topMarginTop = '62px';
    if (width < 900) {
      return topMarginTop
    }

    if (!showHeader) {
      topMarginTop = '0px';
    } else {
      if (isLogin) {
        topMarginTop = '62px';
      } else {
        topMarginTop = '105px';
      }
    }

    return topMarginTop;
  }, [isLogin, showHeader, width]);

  // app sub title
  const getNavItems = useMemo(() => {
    return [
      {
        text: t('Features'),
        path: '/#features',
        gaName: EventName.header_Home_Features,
      },
      {
        text: t('Use Cases'),
        path: '/#use-cases',
        gaName: EventName.header_Home_Use_Cases,
      },
      {
        text: t('Pricing'),
        path: `/pricing`,
        gaName: EventName.header_Plan,
      },
      {
        text: t('KnowBase'),
        path: `/datify`,
        gaName: EventName.header_Datify,
        dropdownItems: [
          {
            text: (
              <div>{t('Datify')}</div>
            ),
            path: `/datify`,
            gaName: EventName.header_sub_Datify,
          },
          {
            text: (
              <div>{t('Categories')}</div>
            ),
            path: `/categories`,
            gaName: EventName.header_sub_Category,
          },
        ]
      },
      {
        text: t('blog'),
        path: `/blog`,
        gaName: EventName.header_Blog,
      },
    ];
  }, [AppSettings.type]);

  const headerContent = useMemo(() => {

    if (width < 900 && router.pathname.startsWith('/dashboard')) {
      return <header className={'header'}>
        <GlobalHeader isMobile={(width < 900)} navItems={getNavItems} />
      </header>
    }

    return (
      <>
        {showHeader && (
          <header className={'header'}>
            <GlobalHeader isMobile={(width < 900)} navItems={getNavItems} />
          </header>
        )}
      </>
    );
  }, [showHeader, width, getNavItems, router.pathname]);

  const footerContent = useMemo(() => {
    return (
      <>
        {showFooter && (
          <footer className={'footer'}>
            <GlobalFooter pageType={pageType} />
          </footer>
        )}
      </>
    );
  }, [showFooter, pageType]);

  return (
    <div className={'layout'}>
      {head}
      {headerContent}
      <main
        // ref={ref}
        style={{ marginTop: getMarginTop }}
        className={classNames('main', {
          mobile: width < 900,
        })}
      >
        {children}
      </main>
      {footerContent}
    </div>
  );
};
export default Layout;
