import Head from 'next/head';
import type { GetServerSideProps, InferGetStaticPropsType } from 'next';
import { NextPageWithLayout } from './_app';
import React, { useMemo } from 'react';
import Layout from '../layouts';
import { AppSettings, PageType } from '@/shared/app-common';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import dynamic from 'next/dynamic';
import { AllSupportLanguages } from '@/shared/language';
import { fetchLibraryInfo } from '@/lib/service';

const HomeContent = dynamic(() => import('@/components/Home'), {
  ssr: true,
});

export const getServerSideProps: GetServerSideProps = async (context) => {
  context.res.setHeader(
    'Cache-Control',
    'public, s-maxage=10, stale-while-revalidate=59'
  );

  const { locale, req, resolvedUrl } = context;

  const [
    localeRes,
    infoDataRes
  ] = await Promise.all([
    serverSideTranslations(locale, ['common']),
    fetchLibraryInfo()
  ])

  let currentURL = `https://${req.headers.host}${resolvedUrl}`;
  if (locale != 'en') {
    currentURL = `https://${req.headers.host}/${locale}${resolvedUrl}`;
  }
  const tempData = localeRes['_nextI18Next']['initialI18nStore'][locale]['common'] ?? {};

  // const articleList = await getArticleRecommendList(null, locale);

  return {
    props: {
      ...localeRes,
      herf: currentURL,
      locale,
      articleList: [],
      title: tempData?.head_Meta_Title ?? null,
      description: tempData?.head_Meta_Desc ?? null,
      pageType: PageType.Normal,
      infoData: infoDataRes?.data ?? {},
    },
  };
};

const Home: NextPageWithLayout<InferGetStaticPropsType<typeof getServerSideProps>> = (props) => {
  return useMemo(() => <HomeContent data={props} />, [props]);
};

Home.getLayout = function getLayout(page: React.ReactElement) {
  const title = page?.props?.title;
  const desc = page?.props?.description;

  return (
    <Layout
      head={
        <Head>
          <title>{title}</title>
          <meta name="title" content={title} />
          <meta name="description" content={desc} />

          <meta property="og:title" content={title} />
          <meta property="og:description" content={desc} />
          <meta property="og:image" content={AppSettings.logoUrl} />
          <meta property="og:image:secure_url" content={AppSettings.logoUrl} />
          <meta property="og:type" content="summary" />
          <meta property="og:url" content={page.props?.herf} />

          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={desc} />
          <meta name="twitter:image" content={AppSettings.logoUrl} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content={page.props?.herf} />

          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="canonical" href={page.props?.herf} />
          <link rel="icon" href={AppSettings.icoPath} />

          {AllSupportLanguages.map((item) => {
            return (
              <link
                key={item.code}
                rel="alternate"
                hrefLang={item.code}
                href={`${AppSettings.webHost}/${item.code}`}
              />
            );
          })}
        </Head>
      }
    >
      {page}
    </Layout>
  );
};

export default Home;
